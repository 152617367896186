import { Injectable } from '@angular/core';
import { UserAgentData } from '../models/user-agent-data.model';

@Injectable({
    providedIn: 'root'
})
export class UserAgentService {
    private readonly USER_AGENT_KEY = 'userAgent';
    public userAgent: UserAgentData;
    constructor() { }

    get isMobile(): boolean {
        if (!this.userAgent) {
            return this.detectDeviceType() === 'Mobile';
        }
        return this.userAgent.mobile;
    }

    get getPlaform(): string {
        return this.userAgent.platform;
    }

    detectDeviceType() {
        return /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent)
          ? 'Mobile'
          : 'Desktop';
      
    }


        
      
    getUserAgent(): UserAgentData {
        return navigator['userAgentData'];
    }

    saveUserAgentToLocalStorage(): void {
        const userAgent = this.getUserAgent();
        this.userAgent = userAgent;
        localStorage.setItem(this.USER_AGENT_KEY, JSON.stringify(userAgent));
    }

    getUserAgentFromLocalStorage(): UserAgentData | null {
        return JSON.parse(localStorage.getItem(this.USER_AGENT_KEY));
    }

}