import { Component, OnInit } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Router } from "@angular/router";
@Component({
  selector: "app-login-template",
  templateUrl: "./login-template.component.html",
  styleUrls: ["./login-template.component.scss"],
})
export class LoginTemplateComponent implements OnInit {
  constructor(
    private keycloakService: KeycloakService,
    private router: Router
  ) {}

  async ngOnInit() {
    if (!this.keycloakService.isLoggedIn()) {
      this.keycloakService.login();
    } else {
      this.router.navigateByUrl("/");
    }
  }
}
