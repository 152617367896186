import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { InternalAuthService } from "../../shared/services/internalauth.service";
import { MonitorRolesEnum } from "./utils/monitor-roles.enum";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivateChild {
  constructor(private authService: InternalAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.checkUserRoles([MonitorRolesEnum.FLEET_ADMIN]);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.checkUserRoles([MonitorRolesEnum.FLEET_ADMIN]);
  }

  isAccessAllowed() {
    return this.authService.checkUserRoles([MonitorRolesEnum.FLEET_ADMIN]);
  }
}
