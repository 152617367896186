<mat-progress-bar mode="indeterminate"></mat-progress-bar>
<div class="empty-table">
  <div class="empty-table">
    <img [@fadeIn] class="logo-image" src="assets/img/logos/Mottu_verde.svg" alt="" />
  </div>
  <div>
    <span class="empty-table-text"
      >Aguarde, você está sendo redirecionado...</span
    >
  </div>
</div>
