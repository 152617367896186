import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { UsuarioPerfilEnum } from "src/app/shared/enum/UsuariosEnum";
import { InternalAuthService } from "src/app/shared/services/internalauth.service";

@Injectable()
export class MonitorProfileGuard extends KeycloakAuthGuard {
  isLoggedIn = false;
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authService: InternalAuthService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.href,
      });
    }
    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (!this.isLoggedIn || !this.authService.userLogged) {
      await this.authService.authorize();
    }

    const perfilId = this.authService.userLogged.dataResult.data.perfilId;
    const notAllowed = [
      UsuarioPerfilEnum.Usuario,
      UsuarioPerfilEnum.Suporte,
      UsuarioPerfilEnum.Guincho,
      UsuarioPerfilEnum.AuxiliarRua,
    ].includes(perfilId);

    if (notAllowed) {
      await this.keycloak.logout(window.location.origin);
    }

    return !notAllowed;
  }
}
