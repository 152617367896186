import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RedirectRoutingModule } from "./redirect-routing.module";
import { RedirectComponent } from "./redirect.component";
import { AgmCoreModule } from "@agm/core";
import { environment } from "src/environments/environment";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@NgModule({
  declarations: [RedirectComponent],
  imports: [
    CommonModule,
    RedirectRoutingModule,
    MatProgressBarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey,
    }),
  ],
})
export class RedirectModule {}
