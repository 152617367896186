export class GoogleAddress {
  rua: string;
  numero: string;
  bairro: string;
  cidade: string;
  uf: string;
  cep: string;
  latitude: string;
  longitude: string;
  fullAddress: string;

  constructor(
    rua:string = '', 
    numero:string = '', 
    bairro:string = '',
    cidade:string = '', 
    uf:string = '', 
    cep:string = '', 
    latitude:string = '', 
    longitude:string = '',
    fullAddress: string = ''
  ) {
    this.rua = rua;
    this.numero = numero;
    this.bairro = bairro;
    this.cidade = cidade;
    this.uf = uf;
    this.cep = cep;
    this.latitude = latitude;
    this.longitude = longitude;
    this.fullAddress = fullAddress
  }
}