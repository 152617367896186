import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
  private snackBarConfig = new MatSnackBarConfig();
  private duration = 4000;

  constructor(
    private snackBar: MatSnackBar
  ) { }

  showSnackBar(message: string, status: 'success' | 'error' | 'warning' , duration = this.duration): void {
    this.snackBarConfig.panelClass = this.createStyleClass(status);
    this.snackBarConfig.duration = duration;
    
    this.snackBar.open(message, 'x', this.snackBarConfig);
  }

  createStyleClass(status: 'success' | 'error' | 'warning'): string[] {
    switch (status) {
      case 'success':
        return ['snackbar-success'];
      case 'error':
        return ['snackbar-error'];
      case 'warning':
        return ['snackbar-warning'];
      default:
        return ['snackbar-success'];
    }
  }
}