import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class OneSignalService {
  baseUrl = "https://onesignal.com/";
  appId = "8dbab182-8a6d-46ef-bb03-beaaae6ad643";

  constructor(private http: HttpClient) {}

  setExternalUserId(playerId: string, userId: string) {
    return this.http.put(this.baseUrl + "api/v1/players/" + playerId, {
      app_id: this.appId,
      external_user_id: userId,
    });
  }
}
