import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { InternalAuthService } from "src/app/shared/services/internalauth.service";

@Injectable({
    providedIn: "root",
})
export class RenavamSupportGuard implements CanActivate {
  constructor(
    private authService: InternalAuthService,
    private router: Router
  ) {}

  canActivate(): boolean {
    return this.authService.isSupportUser || this.authService.isRenavamUser;
  }
}
