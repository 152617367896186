import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BaseRequestResult} from '../models/BaseRequestResult.model';
import { Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificacaoService {
  private baseUrl = environment.baseUrlMonitor;

  constructor(private http: HttpClient) {}

  notificacao = new Subject<any>();
  connectedToHub = new Subject<boolean>();
  
  emitConnectedToHub(connected: boolean) {
    this.connectedToHub.next(connected);
  }

  listenConnectedToHub(): Observable<boolean> {
    return this.connectedToHub.asObservable();
  }

  carregarNotificacoesAntigas(): any{
    return this.http.get<BaseRequestResult<boolean>>(this.baseUrl + 'api/v1/Notificacao');
  }
}
