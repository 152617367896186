import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RedirectComponent } from "./redirect.component";
import { AuthGuard } from "../../auth/auth.guard";

const routes: Routes = [
  { path: "", component: RedirectComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RedirectRoutingModule {}
