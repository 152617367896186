import { animate, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { InternalAuthService } from "src/app/shared/services/internalauth.service";

@Component({
  selector: "app-redirect",
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms', style({ opacity: 1 }))
      ])
    ])
  ],
})
export class RedirectComponent implements OnInit {
  constructor(
    private authService: InternalAuthService
  ) {}
  
  async ngOnInit() {
    await this.authService.redirect();
  }

}
