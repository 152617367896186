import {ErrorHandler, Injectable} from '@angular/core';
import {datadogRum} from '@datadog/browser-rum';
import {environment} from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error) {
    const isProduction: boolean = environment.production;

    if (isProduction) {
      datadogRum.addError(error, {error: {stack: error?.stack}});
      return;
    }
    console.error(error);
  }
}
