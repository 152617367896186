import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, first, tap} from 'rxjs/operators';
import {interval, merge} from 'rxjs';
import {Router} from '@angular/router';
import {datadogRum} from '@datadog/browser-rum';
import {environment} from '../../../environments/environment';
import {isSafari} from '../../app.module';

interface AppData {
  forceUpdate: boolean;
}

const PATHS_FORCE_UPDATE = ['/veiculo-selecao', '/app-fields'];

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(private readonly appRef: ApplicationRef, private readonly swUpdate: SwUpdate, private router: Router) {
  }

  public init(): void {
    if (environment.production && !isSafari()) {
      this.setIntervalUpdate();
      this.setPromptUpdate();
    }
  }

  private setIntervalUpdate(): void {
    const appIsStable$ = this.appRef.isStable.pipe(
      first(isStable => isStable === true),
      tap(() => {
        datadogRum.addAction('VERSION: APP IS STABLE');
      })
    );

    const everyTwentyMinutes$ = interval(20 * 60 * 1000);
    const everyTwentyMinutesOrAppIsStable$ = merge(appIsStable$, everyTwentyMinutes$);

    everyTwentyMinutesOrAppIsStable$.subscribe(() => {
      this.verifyUpdate().then();
    });
  }

  private async verifyUpdate() {
    try {
      const updateFound = await this.swUpdate.checkForUpdate();
      datadogRum.addAction(updateFound ? 'VERSION: NEW VERSION' : 'VERSION: NO NEW VERSIONS');
    } catch (err) {
      console.error('Failed to check for updates:', err);
      datadogRum.addAction('VERSION: FAILED CHECK UPDATE', err);
    }
  }

  private setPromptUpdate(): void {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        datadogRum.addAction('VERSION: AVAILABLE', evt);

        const forceUpdate =
          (evt.latestVersion.appData as AppData).forceUpdate ||
          PATHS_FORCE_UPDATE.some(path => this.router.url.includes(path));

        if (forceUpdate) {
          window.location.reload();
          return;
        }
      });
  }
}
