import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectBoxComponent } from './selectBox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { CheckBoxComponent } from './checkbox.component';
import { TextBoxComponent } from './textbox.component';
import { DateTimeComponent } from './Datetime.component';
import { NumberBoxComponent } from './numberbox.component';

@NgModule({
  declarations: [
    SelectBoxComponent,
    CheckBoxComponent,
    TextBoxComponent,
    DateTimeComponent,
    NumberBoxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],exports:[
    SelectBoxComponent,
    CheckBoxComponent,
    TextBoxComponent,
    DateTimeComponent,
    NumberBoxComponent
  ]
})
export class InputModule { }
