import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { InternalAuthService } from "src/app/shared/services/internalauth.service";

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  isLoggedIn = false;
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authService: InternalAuthService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.href,
      });
    }
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    
    if (this.isLoggedIn) {
      if (this.authService.userLogged) {
        return this.validateRoles(route);
      } else {
        await this.authService.authorize();
        return this.validateRoles(route);
      }
    } else {
      //await this.keycloak.login();
      return false;
    }
  }

  validateRoles(route) {
    const requiredRoles = route.data["roles"];
    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
