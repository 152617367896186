import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { InternalAuthService } from '../../shared/services/internalauth.service';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private authService: InternalAuthService, private router: Router) { }

    canActivate(): Observable<boolean> {
        return this.authService.currentUserSubject.pipe(
            take(1),
            map((currentUserSubject: boolean) => {
                if (this.authService.boraboraUser) {
                    return true;
                }
                return false;
            })
        );
    }
}