export enum UsuarioPerfilEnum {
  "Usuario" = 1,
  "Delivery" = 8,
  "Suporte" = 11,
  "Renavam" = 14,
  "Fabrica" = 32,
  "Portaria" = 37,
  "Guincho" = 39,
  "TerceiroAdmin" = 122,
  "AuxiliarRua" = 101
}
