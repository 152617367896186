import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormBuilderComponent } from './dynamic-form/dynamic-form-builder/dynamic-form-builder.component';
import { InputModule } from './inputs/input.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { UsuarioOnlineBadgeComponent } from './usuario-online-badge/usuario-online-badge.component';



@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormBuilderComponent,
    UsuarioOnlineBadgeComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    MaterialModule,
  ], exports: [
    DynamicFormComponent,
    UsuarioOnlineBadgeComponent
  ]
})
export class AtomModule { }
